<script setup lang="ts">
import { default as PImage } from 'primevue/image'
import { default as PButton } from 'primevue/button'
import MascotteIntro from '@/assets/images/mascottes/quiz/MASCOTTE_Quiz_Post_Intro.png'

import { onMounted, ref, useCssModule } from 'vue'
import { useProfileStore } from '@/stores/profile'

const style = useCssModule()
const step = ref(1)
const button = ref()
const { firstName } = useProfileStore()

const emit = defineEmits(['start'])

onMounted(() => {
  // after 1s the mascotte appears
  setTimeout(() => {
    document
      .querySelector(`.${style['VQuiz-Mascotte']}`)
      ?.classList.remove(style['VQuiz-Mascotte--hidden'])
  }, 500)

  // after 1.5s the intro appears
  setTimeout(() => {
    document
      .querySelector(`.${style['VQuiz-Intro']}`)
      ?.classList.remove(style['VQuiz-Intro--hidden'])
  }, 1000)

  // after 2s the button appears
  setTimeout(() => {
    document
      .querySelector(`.${style['VQuizz-Button']}`)
      ?.classList.remove(style['VQuizz-Button--hidden'])
  }, 1500)
})
</script>

<template>
  <div
    class="w-full flex flex-col items-center justify-center flex-grow relative max-w-screen-md mx-auto"
  >
    <div
      class="bg-white rounded-2xl shadow-lg p-3 relative text-primary"
      :class="[$style['VQuiz-Intro'], $style['VQuiz-Intro--hidden']]"
    >
      <p class="text-primary font-bold">{{ firstName }}, c'était une sacrée aventure !</p>
      <p class="text-primary">
        Avant de continuer ta mission avec une nouvelle tâche, regardons ensemble ce que tu as
        appris aujourd’hui.
      </p>
    </div>

    <p-image
      :class="[$style['VQuiz-Mascotte'], $style['VQuiz-Mascotte--hidden']]"
      imageClass="h-full"
      :src="MascotteIntro"
    />

    <div class="absolute bottom-10">
      <p-button
        ref="button"
        :class="[$style['VQuizz-Button'], $style['VQuizz-Button--hidden']]"
        rounded
        label="Démarrer le quiz !"
        severity="light"
        @click="$emit('start')"
      />
    </div>
  </div>
</template>

<style module>
.VOnboarding {
  max-width: 20rem;
  text-align: center;
  position: relative;
  transition: all 0.5s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 60%;
    transform: translateX(-50%) rotate(180deg);
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid white;
  }

  &.VQuiz-Intro--hidden {
    transform: translateY(1000px);
  }
}

.VQuiz-Mascotte {
  transition: all 0.5s ease-in-out;
  max-height: 50%;

  &.VQuiz-Mascotte--hidden {
    transform: translateY(200%);
  }
}

.VQuizz-Button {
  transition: all 0.5s ease-in-out;

  &.VQuizz-Button--hidden {
    transform: translateY(1000px);
  }
}
</style>
