import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useSessionStore } from '@/stores/session'

export const useAppStore = defineStore('App', () => {
  // STORE
  const { sessionUUID } = storeToRefs(useSessionStore())

  // STATE
  const userAgent = ref(window.navigator.userAgent)
  const isStandalone = ref(
    'matchMedia' in window && typeof window.matchMedia === 'function'
      ? window.matchMedia('(display-mode: standalone)').matches
      : false
  )

  // GETTERS (COMPUTED)
  const appType = computed(() => {
    return !isStandalone.value ? 'web' : 'pwa'
  })

  // ACTIONS
  function $reset() {
    userAgent.value = ''
    isStandalone.value = false
  }

  return {
    // STATE
    registrationCtx: sessionUUID,
    userAgent,
    isStandalone,

    // GETTERS
    appType,

    // ACTIONS
    $reset
  }
})
