import { defineStore } from 'pinia'
import { computed, type Ref, watchEffect } from 'vue'
import { useIdle, useLocalStorage } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'
import { DateTime } from 'ts-luxon'

const SESSION_IDLE_DURATION = 15 * 60 * 1000 // 15 minutes
const MAX_SESSION_DURATION_MINUTES = 30 // 30 minutes

interface ISessionUUIDState {
  uuid: string
  createdAt: string
}

export const useSessionStore = defineStore('Session', () => {
  const session: Ref<ISessionUUIDState> = useLocalStorage(
    'sessionUUID',
    {
      uuid: uuidv4(),
      createdAt: DateTime.now().toISO()
    } as ISessionUUIDState,
    { deep: true, listenToStorageChanges: true, writeDefaults: true }
  )

  async function startSessionMonitor(idleDuration: number = SESSION_IDLE_DURATION) {
    const { idle, reset } = useIdle(idleDuration)

    // Vérifier la durée de la session
    const createdAtTime = DateTime.fromISO(session.value.createdAt)
    const currentTime = DateTime.now()

    // Calculer la différence de temps en minutes
    const timeDifference = currentTime.diff(createdAtTime, 'minutes').minutes
    if (timeDifference > MAX_SESSION_DURATION_MINUTES) {
      await startNewSession()
    }

    watchEffect(async () => {
      if (idle.value) {
        await startNewSession()
        reset()
      }
    })
  }

  async function startNewSession() {
    session.value = {
      uuid: uuidv4(),
      createdAt: DateTime.now().toISO()
    }

    // Send xAPI message
  }

  // GETTERS
  const sessionUUID = computed(() => session.value.uuid)

  return {
    startSessionMonitor,
    sessionUUID
  }
})
