import type { ILearningGrain } from '@/assets/ts/types/learning/learningGrain'
import type { IScore } from '@/assets/ts/types/progress/Progress'
import type { ILearningSupport } from '@/assets/ts/types/learning/learningSupports'
import { Support } from '@/assets/ts/classes/progress/support'

export class Task {
  title: string
  grainId: number
  order: number
  supports: Support[]
  isCompleted: boolean
  isStarted: boolean
  preQuizId: number
  preQuizCompleted: boolean
  postQuizId: number
  estimatedTime: number
  taskScore: IScore

  constructor(obj: ILearningGrain) {
    this.title = obj.grainTitle
    this.grainId = obj.grainId
    this.order = obj.grainOrder
    this.supports = obj.supports.map((support) => new Support(support))
    this.isCompleted = obj.isCompleted
    this.isStarted = obj.isStarted
    this.preQuizId = obj.preQuizId
    this.preQuizCompleted = obj.preQuizCompleted
    this.postQuizId = obj.postQuizId
    this.estimatedTime = obj.estimatedTime
    this.taskScore = obj.grainScore
  }

  get taskLabel() {
    return `Tâche ${this.order.toString().padStart(2, '0')}`
  }

  get nextSupportIndex() {
    return this.supports.findIndex((support) => !support.isCompleted)
  }

  start() {
    this.isStarted = true
  }

  setScore(score: number) {
    this.taskScore.score = score
  }
}
