import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'

import App from './App.vue'
import { SentryExceptionDto } from '@/assets/ts/DTO/sentry.dto'

// Styles
import 'vue3-carousel/dist/carousel.css'
import './assets/styles/scss/main.scss'
import 'primeicons/primeicons.css'

// Plugins
import { registerSW } from 'virtual:pwa-register'
import { registerPlugins } from '@/plugins'

const app = createApp(App)

registerPlugins(app)

registerSW({
  immediate: true,
  onRegisterError(error: any) {
    Sentry.captureException(new SentryExceptionDto({ error }))
  },
  onOfflineReady() {
    // show a ready to work offline to user
  }
})

app.mount('#app')

export { app }
