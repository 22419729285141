<script setup lang="ts">
import { default as PTag } from 'primevue/tag'
import { default as PButton } from 'primevue/button'
import { computed } from 'vue'
import EIcons from '@/components/elements/icons/EIcons.vue'
import EIcon from '@/components/elements/icons/EIcon.vue'
import type { IProgressQuiz } from '@/assets/ts/types/progress/Progress'
import vHighLightAndDuplicate from '@/assets/ts/directives/DuplicateAndHighlight'
import { storeToRefs } from 'pinia'
import { useProfileStore } from '@/stores/profile'
import { useRouter } from 'vue-router'

const props = defineProps<{
  quiz: IProgressQuiz
  highlight?: boolean
}>()

const router = useRouter()

const { preferences } = storeToRefs(useProfileStore())

const explorationName = computed(() => {
  return 'exploration ' + props.quiz.learningCourseName
})

const { putPreferences } = useProfileStore()

const completeDiagnosticOnboarding = async () => {
  const newPreferences = {
    ...preferences.value
  }

  newPreferences.onboarding.isDiagnosticCompleted = true

  await putPreferences(newPreferences)

  await router.push({
    name: 'diagnostic',
    query: {
      exploration: props.quiz.learningCourseSlug
    }
  })
}
</script>

<template>
  <e-icons :load="['Play']" />
  <div
    v-high-light-and-duplicate="{
      enabled: props.highlight && !preferences.onboarding.isDiagnosticCompleted,
      message: 'Tu as débloqué<br/> <span class=\'font-bold\'>ton premier test d\'entrée</span>',
      action: completeDiagnosticOnboarding
    }"
    class="bg-primary-night-blue p-4 rounded-lg relative flex items-center gap-4 shadow-2xl"
    :class="[$style.MissionBlock]"
    @click="
      () =>
        $router.push({
          name: 'diagnostic',
          query: {
            exploration: props.quiz.learningCourseSlug
          }
        })
    "
  >
    <div class="grow relative">
      <p-tag
        class="uppercase font-secondary font-medium px-2 py-0"
        :class="['bg-orange-300 text-orange-900 ']"
        rounded
      >
        {{ explorationName }}
      </p-tag>
      <p class="text-white font-bold mt-1">Nouveau test d'entrée disponible</p>
    </div>

    <p-button
      text
      icon="pi pi-play"
      class="ml-auto w-16 h-16 py-3 pl-4 pr-3"
      severity="primary"
      rounded
    >
      <e-icon name="Play" type="svg" style="--fill: var(--variant-white)" />
    </p-button>
  </div>
</template>

<style module>
.MissionBlock {
  position: relative;
  background: var(--linear-04);

  .MissionBlockTasks {
    --taskWidth: 0;
    position: relative;
    background: rgb(var(--primary-night-blue));
    border-radius: 0.5rem;
    height: 10px;
    width: 100%;

    &::before {
      content: '';
      display: block;
      position: relative;
      width: var(--taskWidth);
      height: 100%;
      background-color: var(--primary-lavender);
      border-radius: 0.5rem;
    }
  }
}

.MissionBlock::before {
  content: '';
  position: absolute;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(var(--primary-night-blue));
  border-radius: var(--p-content-border-radius);
}
</style>
