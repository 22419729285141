<script setup lang="ts">
import OnboardingStep1 from '@/views/onboarding/blocks/OnboardingStep1.vue'
import OnboardingStep2 from '@/views/onboarding/blocks/OnboardingStep2.vue'
import OnboardingStep3 from '@/views/onboarding/blocks/OnboardingStep3.vue'
import OnboardingStep4 from '@/views/onboarding/blocks/OnboardingStep4.vue'
import OnboardingStep5 from '@/views/onboarding/blocks/OnboardingStep5.vue'
import OnboardingStep6 from '@/views/onboarding/blocks/OnboardingStep6.vue'
import OnboardingStep7 from '@/views/onboarding/blocks/OnboardingStep7.vue'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useProfileStore } from '@/stores/profile'
import { storeToRefs } from 'pinia'

// router
const router = useRouter()

const steps = ref(1)
const { preferences } = storeToRefs(useProfileStore())

const { putPreferences } = useProfileStore()

const nextStep = () => {
  steps.value++
}

const endOnboarding = async () => {
  const newPreferences = {
    ...preferences.value
  }

  newPreferences.onboarding.isGeneralCompleted = true

  await putPreferences(newPreferences)
  await router.push({ name: 'logbook' })
}

const componentToDisplay = computed(() => {
  switch (steps.value) {
    case 1:
      return OnboardingStep1
    case 2:
      return OnboardingStep2
    case 3:
      return OnboardingStep3
    case 4:
      return OnboardingStep4
    case 5:
      return OnboardingStep5
    case 6:
      return OnboardingStep6
    case 7:
      return OnboardingStep7
    default:
      return OnboardingStep1
  }
})
</script>

<template>
  <div
    class="flex flex-col items-center justify-center w-full flex-grow relative h-full"
    :class="[$style['Step-1']]"
  >
    <component :is="componentToDisplay" @next-step="nextStep" @end="endOnboarding" />
  </div>
</template>

<style module>
.Step-1 {
  background: var(--linear-02);
}
</style>
