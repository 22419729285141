<script setup lang="ts">
import { LearningEnums } from '@/assets/ts/types/learning/enums'
import type { Task } from '@/assets/ts/classes/progress/task'
import { default as PButton } from 'primevue/button'
import { computed, onMounted, ref } from 'vue'
import MascotIntro from '@/assets/images/mascottes/MASCOTTE_Joie.svg'
import MascotIntroDanger from '@/assets/images/mascottes/MASCOTTE_Danger.svg'
import MascotEnd from '@/assets/images/mascottes/quiz/MASCOTTE_Quiz_Post_Intro.png'
import { useProfileStore } from '@/stores/profile'

const emit = defineEmits(['start'])
const props = defineProps<{
  type: LearningEnums.QuizType
  task: Task
}>()

const { firstName } = useProfileStore()
const step = ref(1)
const showElements = ref([false, false, false, false])

onMounted(() => {
  displayElements()
})

const nextStep = () => {
  showElements.value = [false, false, false, false]
  step.value++
  displayElements()
}

const displayElements = () => {
  // set each element to true after a delay one by one

  setTimeout(() => {
    showElements.value[1] = true

    setTimeout(() => {
      showElements.value[2] = true

      setTimeout(() => {
        showElements.value[3] = true

        setTimeout(() => {
          showElements.value[4] = true
        }, 500)
      }, 500)
    }, 500)
  }, 500)
}

const contents = computed(() => {
  const contents = {
    label: {
      value: props.task.taskLabel
    },
    title: {
      value: props.task.title,
      color: 'text-orange-400'
    },
    button: {
      value: "C'est parti !",
      action: () => nextStep()
    },
    mascot: {
      value: MascotIntro
    }
  }

  if (step.value === 2) {
    contents.label.value = 'Tomberas-tu dans'
    contents.title = { value: 'le piège des idées reçues ?', color: 'text-white' }
    contents.button = { value: 'On y va !', action: () => emit('start') }
    contents.mascot.value = MascotIntroDanger
  }

  return contents
})
</script>

<template>
  <div class="flex flex-col items-center mt-10 gap-4 max-w-screen">
    <transition name="slide-from-right">
      <h3 v-if="showElements[1]" class="heading large text-center">{{ contents.label.value }}</h3>
    </transition>

    <transition name="slide-from-right">
      <h2
        v-if="showElements[2]"
        class="brand-heading uppercase text-center"
        :class="contents.title.color"
      >
        {{ contents.title.value }}
      </h2>
    </transition>

    <transition name="slide-from-right">
      <p-button
        v-if="showElements[3]"
        :label="contents.button.value"
        severity="light"
        rounded
        class="mt-4 z-10"
        @click="contents.button.action"
      />
    </transition>

    <transition name="slide-from-bottom">
      <img v-if="showElements[4]" :src="contents.mascot.value" class="absolute bottom-0 max-h-96" />
    </transition>
  </div>
</template>

<style scoped>
.slide-from-right-enter-active,
.slide-from-right-leave-active {
  transition: all 0.3s ease;
}

.slide-from-right-enter-from {
  transform: translateX(1000px);
  opacity: 0;
}

.slide-from-right-leave-to {
  transform: translateX(-1000px);
  opacity: 0;
}

.slide-from-bottom-enter-active,
.slide-from-bottom-leave-active {
  transition: all 0.3s ease;
}

.slide-from-bottom-enter-from,
.slide-from-bottom-leave-to {
  transform: translateY(1000px);
  opacity: 0;
}
</style>
