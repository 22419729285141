<script setup lang="ts">
import { computed } from 'vue'
import { default as PInputText } from 'primevue/inputtext'
import { default as PButton } from 'primevue/button'
import { default as PMessage } from 'primevue/message'
import type { ISignupEmailInput } from '@/assets/ts/DTO/signup/signup.dto'
import { storeToRefs } from 'pinia'
import { useSignupStore } from '@/stores/signupStore'

const form = defineModel<ISignupEmailInput>('form', {
  required: true
})

const { loading, hasError } = storeToRefs(useSignupStore())

const emit = defineEmits(['validate'])

const emailsMatch = computed(() => {
  //if (form.value.email && !form.value.confirmEmail) return true
  return form.value.email === form.value.confirmEmail
})

const emailIsValid = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
  return form.value.email.match(emailPattern)
}

const preventPaste = (event: ClipboardEvent) => {
  event.preventDefault()
}

const handleSubmit = (event: Event) => {
  const formElement = event.target as HTMLFormElement
  event.preventDefault()
  if (!formElement.checkValidity() || !emailsMatch.value || !emailIsValid()) {
    formElement.reportValidity()
  } else {
    emit('validate')
  }
}
</script>

<template>
  <form
    data-form-type="other"
    autocomplete="off"
    class="w-full flex flex-col gap-4 items-center"
    @submit.prevent="handleSubmit"
  >
    <div class="w-full flex flex-col gap-1">
      <label for="email" class="text text-primary w-full"> Ton adresse email </label>
      <p-input-text
        class="w-full"
        placeholder="email"
        data-form-type="other"
        autocomplete="off"
        required
        type="email"
        v-model="form.email"
        name="email"
        id="email"
        autofocus
        @blur="emailIsValid"
        @paste="preventPaste"
      />
    </div>

    <div class="w-full flex flex-col gap-1">
      <label for="confirm_email" class="text text-primary w-full">
        Confirme ton adresse email
      </label>
      <p-input-text
        class="w-full"
        placeholder="email"
        data-form-type="other"
        autocomplete="off"
        required
        type="email"
        v-model="form.confirmEmail"
        name="confirm_email"
        id="confirm_email"
        :disabled="!emailIsValid()"
        @paste.prevent
      />
    </div>

    <!-- Message d'erreur si le format du mail n'est pas correct -->
    <p-message v-if="form.email && !emailIsValid()" severity="error" icon="pi pi-times-circle">
      <span class="text small">L'adresse email n'est pas valide.</span>
    </p-message>

    <!-- Message d'erreur si les emails ne correspondent pas -->
    <p-message
      v-if="form.email && !emailsMatch && emailIsValid()"
      severity="error"
      icon="pi pi-times-circle"
    >
      <span class="text small">Les adresses email ne correspondent pas.</span>
    </p-message>

    <p-message v-if="hasError" severity="error" icon="pi pi-times-circle">
      <span class="text small"
        >Une erreur est survenue, merci contacte ton professeur pour obtenir de l’aide</span
      >
    </p-message>

    <p-button
      :loading="loading"
      :disabled="!form.email || !emailsMatch || !emailIsValid()"
      label="Je m'inscris"
      rounded
      severity="primary"
      type="submit"
    />
  </form>
</template>
