<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import mascotImage from '@/assets/images/onboarding/Onboarding_Nab_Com.png'
import { default as PButton } from 'primevue/button'

const props = defineProps<{
  message: string
  position: {
    name: string
    value: {
      top: number
      left: number
    }
  }
}>()
const emit = defineEmits(['primaryClick', 'secondaryClick'])

const onPrimaryClick = () => emit('primaryClick')

const positionComputed = {
  top: `${props.position.value.top}px`,
  left: `calc(${props.position.value.left}px - 1rem)`
}
</script>

<template>
  <div
    class="popup-message flex flex-col gap-2 justify-center items-center w-full max-w-screen-sm"
    :style="positionComputed"
  >
    <div
      class="popup-content grid grid-cols-12 gap-2 justify-center items-center w-full"
      :class="{ top: position.name === 'top' }"
    >
      <div class="col-span-8">
        <h3 class="brand-heading large text-lavender text-left">Nab</h3>
        <div v-html="message" class="text-primary text-left col-span-8" />
      </div>
      <img :src="mascotImage" alt="Mascotte" class="col-span-4 ml-auto" />
      <div class="col-span-12 flex justify-end">
        <p-button severity="primary" rounded @click="onPrimaryClick" label="C'est parti !" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.popup-message {
  position: absolute;
  z-index: 1000;
}

.popup-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    bottom: calc(100% - 1px);
    left: 80%;
    transform: translateX(50%);
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid white;
  }

  &.top {
    &::before {
      top: calc(100% - 1px);
      bottom: auto;
      transform: translate(50%) rotate(180deg);
    }
  }
}
</style>
