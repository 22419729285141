<script setup lang="ts">
import { default as PInputText } from 'primevue/inputtext'
import { default as PButton } from 'primevue/button'
import { default as PMessage } from 'primevue/message'
import type { ISignupLookupInput } from '@/assets/ts/DTO/signup/signup.dto'
import { storeToRefs } from 'pinia'
import { useSignupStore } from '@/stores/signupStore'

const form = defineModel<ISignupLookupInput>('form', {
  required: true
})

const { loading, hasError, errorType } = storeToRefs(useSignupStore())

const emit = defineEmits(['validate'])

const handleSubmit = (event: Event) => {
  const formElement = event.target as HTMLFormElement
  event.preventDefault()
  if (!formElement.checkValidity()) {
    formElement.reportValidity()
  } else {
    emit('validate')
  }
}
</script>

<template>
  <form
    class="w-full flex flex-col gap-4 items-center"
    data-form-type="other"
    autocomplete="off"
    @submit.prevent="handleSubmit"
  >
    <div class="w-full flex flex-col gap-1">
      <label for="schoolcode" class="text text-primary w-full">
        Le code de ton établissement
      </label>
      <p-input-text
        class="w-full"
        placeholder="Code établissement"
        autocomplete="off"
        required
        type="text"
        v-model="form.schoolCode"
        name="schoolCode"
        id="schoolCode"
      />
    </div>

    <div class="w-full flex flex-col gap-1">
      <label for="firstname" class="text text-primary w-full"> Ton prénom </label>
      <p-input-text
        class="w-full"
        placeholder="Prénom"
        data-form-type="firstname"
        autocomplete="off"
        required
        type="text"
        name="firstname"
        id="firstname"
        v-model="form.firstname"
      />
    </div>

    <div class="w-full flex flex-col gap-1">
      <label for="lastname" class="text text-primary w-full"> Ton nom de famille </label>
      <p-input-text
        class="w-full"
        placeholder="Nom"
        autocomplete="off"
        data-form-type="lastname"
        required
        type="text"
        name="lastname"
        id="lastname"
        v-model="form.lastname"
      />
    </div>

    <p-message
      v-if="hasError && errorType !== 'ALREADY_REGISTERED'"
      severity="error"
      icon="pi pi-times-circle"
    >
      <span class="text small">
        Nous n’avons pas réussi à t’identifier. Vérifie que tu n’as fais aucune erreur, puis
        contacte ton professeur pour obtenir de l’aide.
      </span>
    </p-message>

    <p-message
      v-if="hasError && errorType === 'ALREADY_REGISTERED'"
      severity="error"
      icon="pi pi-times-circle"
    >
      <span class="text small">
        Tu es déjà inscrit. Si tu as oublié ton mot de passe, tu peux le réinitialiser en cliquant
        sur le lien "Mot de passe oublié" sur l'écran de connexion.
      </span>
    </p-message>

    <p-button :loading="loading" label="Je m'inscris" rounded severity="primary" type="submit" />
  </form>
</template>
