<script setup lang="ts">
import { default as PButton } from 'primevue/button'
import { default as PImage } from 'primevue/image'
import { useProfileStore } from '@/stores/profile'
import MascotteProfileUrl from '@/assets/images/mascottes/profile/MASCOTTE_Profile.png'
import { useAuthStore } from '@/stores/auth'
import { useConfirm } from 'primevue/useconfirm'

const { userName, userPicture, me, toggleProfile, requestPasswordChange } = useProfileStore()
const { initLogout } = useAuthStore()

const confirm = useConfirm()

function handleConfirm() {
  confirm.require({
    header: 'Changement de mot de passe',
    message: 'Êtes-vous sûr de vouloir changer votre mot de passe ?',
    rejectProps: {
      label: 'Non',
      severity: 'secondary'
    },
    acceptProps: {
      label: 'Oui',
      severity: 'primary'
    },
    accept: async () => await goToChangePasswordPage(),
    reject: () => {}
  })
}

/**
 * Ask the user to change his password
 */
async function goToChangePasswordPage() {
  try {
    const res = await requestPasswordChange()
    window.location.href = res.link
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <div
    class="absolute top-0 left-0 flex flex-col gap-8 w-screen h-screen z-50 bg-primary-night-blue center p-4 px-6"
  >
    <div class="absolute top-4 right-4">
      <p-button rounded text severity="light" icon="pi pi-times" @click="toggleProfile" />
    </div>

    <h2 class="text-left brand-heading">Mon compte</h2>

    <div class="bg-primary-500 rounded-2xl p-3 flex flex-col gap-4">
      <div class="flex items-center gap-2">
        <div class="w-10 h-10">
          <p-image
            class="border-2 border-white rounded-full"
            image-class="w-full h-full"
            :src="userPicture"
            alt="WelcomeProgress"
          />
        </div>
        <h3 class="brand-heading medium">{{ userName }}</h3>
      </div>

      <p><i class="pi pi-calendar" /> Année 2024-2025</p>
    </div>

    <div class="bg-primary-500 rounded-2xl p-3 flex flex-col items-start gap-4">
      <h2 class="text-left brand-heading">Mes infos</h2>

      <p>Identifiant : {{ me.email }}</p>

      <p-button outlined rounded severity="light" @click="handleConfirm()"
        >Modifier mon mot de passe
      </p-button>
    </div>

    <p-button
      icon="pi pi-sign-out"
      label="Déconnexion"
      class="self-center"
      outlined
      severity="light"
      rounded
      @click="initLogout"
    />

    <p-image
      class="absolute bottom-0 right-0 max-w-0"
      image-class="w-full"
      :src="MascotteProfileUrl"
    />
  </div>
</template>
