import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import { useLocalStorage } from '@vueuse/core'
import * as Sentry from '@sentry/vue'
import { NabooError } from '@/assets/ts/classes/Error'
import { SentryExceptionDto } from '@/assets/ts/DTO/sentry.dto'
import type { IUser, IUserPreferences } from '@/assets/ts/types/UserType'
import { useProgressStore } from '@/stores/progress'
import { xAPIMessageBuilder } from '@/assets/ts/classes/xApi'
import type { UUID } from '@/assets/ts/types/xAPI/xAPI'
import { usexAPIStore } from '@/stores/xAPI'
import { useSessionStore } from '@/stores/session'

export const useProfileStore = defineStore('Profile', () => {
  const progressStore = useProgressStore()
  const { addToQueue } = usexAPIStore()
  const { sessionUUID } = storeToRefs(useSessionStore())

  // STATE
  const me = useLocalStorage('me', {} as IUser)
  const forceDisplayMobile = useLocalStorage('forceDisplayMobile', false)
  const points = useLocalStorage('points', 0)
  const displayProfileOverlay = ref(false)

  // MUTATIONS
  function toggleProfile() {
    displayProfileOverlay.value = !displayProfileOverlay.value
  }

  // ACTIONS
  const initSessionUUID = async () => {
    if (!sessionUUID.value) {
      //setSessionUUID(uuidv4())
      const message = xAPIMessageBuilder.createLaunchedApplicationMessage(
        me.value.identifier as UUID,
        sessionUUID.value
      )

      await addToQueue(message)
    }
  }

  function toggleForceDisplayMobile() {
    forceDisplayMobile.value = !forceDisplayMobile.value
  }

  function $reset() {
    me.value = {} as IUser
  }

  async function getMe() {
    try {
      me.value = await nabooApi.getMe()
      await progressStore.getGlobalProgress()
      return Promise.resolve()
    } catch (error) {
      if (!(error instanceof NabooError)) Sentry.captureException(new SentryExceptionDto({ error }))
      me.value = {} as IUser
      return Promise.reject(error)
    }
  }

  async function putTrackingConsentBanner() {
    try {
      me.value = await nabooApi.putTrackingConsentBanner()
      return Promise.resolve()
    } catch (error) {
      if (!(error instanceof NabooError)) Sentry.captureException(new SentryExceptionDto({ error }))
      return Promise.reject(error)
    }
  }

  async function putPreferences(preferences: IUserPreferences) {
    try {
      me.value = await nabooApi.putPreferences(preferences)
      return Promise.resolve()
    } catch (error) {
      if (!(error instanceof NabooError)) Sentry.captureException(new SentryExceptionDto({ error }))
      return Promise.reject(error)
    }
  }

  /**
   * Request a password change
   */
  async function requestPasswordChange() {
    try {
      const res = nabooApi.requestPasswordChange()
      return Promise.resolve(res)
    } catch (error) {
      const e = error as Error
      if (!(error instanceof NabooError)) Sentry.captureException(new SentryExceptionDto({ error }))
      e.message = 'Une erreur est survenue lors de la demande de changement de mot de passe.'
      return Promise.reject(e)
    }
  }

  // GETTERS (COMPUTED)
  const getFirstNameInitial = computed(() => {
    if (!me.value?.firstname) return ''
    return me.value.firstname?.charAt(0)
  })

  const userPoints = computed(() => points.value ?? 0)

  const shortUsername = computed(() => {
    if (!me.value?.firstname) return ''
    return `${me.value.firstname} ${me.value.lastname.charAt(0)}.`
  })

  const firstName = computed(() => me.value?.firstname ?? '')

  const userName = computed(() => {
    if (!me.value?.firstname) return ''
    return `${me.value.firstname} ${me.value.lastname}`
  })

  const hasAnsweredTrackingConsentBanner = computed(
    () => me.value?.hasAnsweredTrackingConsentBanner ?? false
  )
  const hasConsentedForTracking = computed(() => me.value?.hasConsentedForTracking ?? false)

  const userIdentifier = computed(() => me.value.identifier ?? '')

  const userPicture = computed(() => me.value.picture ?? '')

  const preferences = computed(() => me.value.preferences)

  return {
    // STATE
    me,
    forceDisplayMobile,
    sessionUUID,
    displayProfileOverlay,
    // MUTATIONS
    toggleProfile,
    // ACTIONS
    initSessionUUID,
    toggleForceDisplayMobile,
    $reset,
    getMe,
    putTrackingConsentBanner,
    putPreferences,
    requestPasswordChange,
    // GETTERS (COMPUTED)
    shortUsername,
    getFirstNameInitial,
    userPoints,
    userName,
    firstName,
    userIdentifier,
    hasAnsweredTrackingConsentBanner,
    hasConsentedForTracking,
    userPicture,
    preferences
  }
})
