<script setup lang="ts">
import MascotteQuizThemeUrl from '@/assets/images/mascottes/quiz/MASCOTTE_Quiz_theme.png'
import { default as PImage } from 'primevue/image'
import { default as PButton } from 'primevue/button'
import { onMounted, ref } from 'vue'

defineProps<{
  themeTitle: string
  questionsCount: number
  quizOrder?: number
}>()

const themeImageHtml = ref()
const themeNameHtml = ref()
const themeTitlehtml = ref()
const questionsCountHtml = ref()
const buttonHtml = ref()

onMounted(() => {
  // each element appears with a delay
  setTimeout(() => {
    themeImageHtml.value.$el.classList.remove('opacity-0')
  }, 500)

  setTimeout(() => {
    themeNameHtml.value.classList.remove('opacity-0')
  }, 1000)

  setTimeout(() => {
    themeTitlehtml.value.classList.remove('opacity-0')
  }, 1500)

  setTimeout(() => {
    questionsCountHtml.value.classList.remove('opacity-0')
  }, 2000)

  setTimeout(() => {
    buttonHtml.value.$el.classList.remove('opacity-0')
  }, 2500)
})
</script>

<template>
  <div
    class="flex flex-col items-center justify-around w-full flex-grow relative max-w-screen-md mx-auto"
  >
    <p-image
      ref="themeImageHtml"
      class="w-60 opacity-0 transition"
      image-class="w-full"
      :src="MascotteQuizThemeUrl"
    />

    <div class="flex flex-col gap-2 items-center">
      <h2 ref="themeNameHtml" class="heading large transition opacity-0">Thème {{ quizOrder }}</h2>
      <h3 ref="themeTitlehtml" class="heading medium opacity-0 transition">{{ themeTitle }}</h3>
      <span
        ref="questionsCountHtml"
        class="text text--small font-bold bg-primary-night-blue px-2 py-1 rounded mt-10 opacity-0 transition"
      >
        {{ questionsCount }} questions
      </span>
    </div>

    <p-button
      class="opacity-0 transition"
      ref="buttonHtml"
      severity="light"
      rounded
      label="Démarrer"
      @click="$emit('start')"
    />
  </div>
</template>
