<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  type: 'prequiz' | 'support' | 'postquiz'
  state: 'completed' | 'current' | 'locked' | 'maxScore'
}>()

const stateClasses = computed(() => {
  switch (props.state) {
    case 'completed':
      return props.type === 'postquiz' ? 'postquiz-completed' : 'completed-default'
    case 'current':
      return props.type === 'postquiz' ? 'postquiz-current' : 'current-default'
    case 'maxScore':
      return 'postquiz-max-score'
    case 'locked':
      return 'locked'
    default:
      return 'default'
  }
})
</script>

<template>
  <div class="rounded-lg p-4 h-20 flex items-center" :class="stateClasses">
    <slot />
  </div>
</template>

<style scoped>
.locked {
  @apply border border-blue-800 border-solid;
}

.current-default {
  @apply bg-naboo-blue;
}

.completed-default,
.postquiz-max-score,
.default {
  @apply bg-blue-800;
}

.postquiz-completed {
  background: var(--linear-02);
}

.postquiz-current {
  background: var(--linear-04);
}
</style>
