<script setup lang="ts">
// PRIMEVUE COMPONENTS
import { default as PButton } from 'primevue/button'
import { default as PDrawer } from 'primevue/drawer'

// CUSTOM COMPONENTS
import ENavigationButton from '@/components/elements/buttons/ENavigationButton.vue'

// IMAGES
import CloseOverlayImage from '@/assets/images/mascottes/Close_Support_Overlay.png'
import AdvicesImage from '@/assets/images/mascottes/Support_Advices.png'
import CheckmarkImage from '@/assets/images/check.png'

// VUEUSE, VUE, ROUTER, ETC...
import { useElementSize } from '@vueuse/core'
import { onUnmounted, ref, watch } from 'vue'

// Refs and variables
const iframeWrapperRef = ref<HTMLElement | null>(null)
const { width, height } = useElementSize(iframeWrapperRef)
const closeDrawerOverlay = ref(false)
const closingStep = ref(1)
const timer = ref<number | undefined>(undefined)
const elapsedTime = ref(0)
const showAdvicesPanel = ref(false)

// Props
defineProps<{
  iframeURL: string
  supportIsCompleted: boolean
}>()

// Models
const drawerVisible = defineModel('visible', {
  type: Boolean,
  default: false
})

// Emit
const emit = defineEmits(['completeSupport'])

// Fonction pour mettre à jour les variables CSS
const updateCSSVariables = () => {
  if (width.value && height.value) {
    document.documentElement.style.setProperty('--drawer-width', `${width.value}px`)
    document.documentElement.style.setProperty('--drawer-height', `${height.value}px`)
  }
}

// Observer les changements de dimensions
watch([width, height], () => {
  if (drawerVisible.value) {
    updateCSSVariables()
  }
})

watch(drawerVisible, (value) => {
  if (value) {
    // Display the advices panel
    showAdvicesPanel.value = true
    // start timer
    timer.value = setInterval(() => {
      elapsedTime.value++
    }, 1000)
  }
})

// Stop the timer if component unmounts
onUnmounted(() => {
  if (timer.value) clearInterval(timer.value)
})

const closeIframeDrawer = () => {
  drawerVisible.value = false
  closeDrawerOverlay.value = false
}

const toggleConfirmCloseOverlay = () => {
  // Pause the timer when the overlay is opened
  if (timer.value) clearInterval(timer.value)
  closingStep.value = 1
  closeDrawerOverlay.value = !closeDrawerOverlay.value

  if (!timer.value) {
    // Restart the timer when the overlay is closed
    timer.value = setInterval(() => {
      elapsedTime.value++
    }, 1000)
  }
}

const completeSupport = () => {
  emit('completeSupport', elapsedTime.value)
  closeIframeDrawer()
}
</script>

<template>
  <div class="grid grid-cols-1">
    <p-drawer
      v-model:visible="drawerVisible"
      position="full"
      :modal="true"
      :blockScroll="true"
      :closable="true"
      class="bg-primary-night-blue"
      style="--p-drawer-color: var(--variant-white)"
    >
      <template #container>
        <div :class="$style.drawerContainer">
          <div class="basis-1/12" />
          <div class="basis-10/12 justify-self-stretch self-stretch grow" ref="iframeWrapperRef">
            <div :class="$style.rotatedContainer">
              <div
                v-if="showAdvicesPanel"
                class="absolute top-0 left-0 w-full h-full bg-primary-dark-blue-500 flex justify-center items-center"
              >
                <div class="bg-surface-blue rounded-2xl flex flex-col gap-2 p-4">
                  <div class="flex gap-2 mb-4">
                    <div class="flex-1 flex flex-col gap-2">
                      <h3 class="brand-heading large">Bienvenue dans ce nouveau support</h3>

                      <p>Voici quelques conseils pour suivre au mieux ces contenus :</p>
                    </div>
                    <img :src="AdvicesImage" alt="Mascotte donnant des conseils" />
                  </div>

                  <div class="flex flex-col gap-2">
                    <div class="flex gap-2">
                      <img :src="CheckmarkImage" alt="Checkmark" />
                      <p>Choisis un endroit calme</p>
                    </div>

                    <div class="flex gap-2">
                      <img :src="CheckmarkImage" alt="Checkmark" />
                      <p>Utilise des écouteurs ou un casque</p>
                    </div>

                    <div class="flex gap-2">
                      <img :src="CheckmarkImage" alt="Checkmark" />
                      <p>Munis-toi d’un carnet et d’un style pour prendre des notes</p>
                    </div>

                    <div class="flex gap-2">
                      <img :src="CheckmarkImage" alt="Checkmark" />
                      <p>Mets ton portable en veille</p>
                    </div>

                    <div class="flex gap-2">
                      <img :src="CheckmarkImage" alt="Checkmark" />
                      <p>Reste positif et motivé !</p>
                    </div>
                  </div>

                  <div class="flex gap-2 justify-center items-center">
                    <p-button
                      label="C'est parti !"
                      @click="showAdvicesPanel = false"
                      severity="light"
                      rounded
                    />
                  </div>
                </div>
              </div>
              <iframe
                width="100%"
                height="100%"
                :src="iframeURL"
                title="Support Naboo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                sandbox="allow-same-origin allow-scripts"
              />
            </div>
          </div>
          <div :class="$style.closeContainer">
            <e-navigation-button icon="Close" :action="toggleConfirmCloseOverlay" />
          </div>
        </div>
      </template>
    </p-drawer>

    <!-- Overlay to confirm closing the support -->
    <p-drawer
      v-model:visible="closeDrawerOverlay"
      :modal="true"
      :blockScroll="true"
      :closable="true"
      position="full"
      style="--p-drawer-color: var(--variant-white)"
      class="bg-primary-dark-blue-500"
    >
      <template #container>
        <div :class="$style.drawerContainer">
          <div class="basis-1/12"></div>
          <div class="basis-10/12 justify-self-stretch self-stretch grow">
            <div :class="$style.rotatedContainer" class="flex items-center justify-center">
              <div class="flex flex-col gap-4 h-full items-center justify-center">
                <!-- Template for finished support -->
                <template v-if="supportIsCompleted">
                  <p>Que souhaites-tu faire ?</p>
                  <div class="flex gap-2 justify-center items-center">
                    <p-button
                      label="Je continue mon support"
                      @click="toggleConfirmCloseOverlay"
                      severity="light"
                      rounded
                    />
                    <p-button
                      label="Quitter"
                      @click="closeIframeDrawer"
                      outlined
                      severity="light"
                      rounded
                    />
                  </div>
                </template>

                <!-- Template for unfinished support step 1 -->
                <template v-else-if="closingStep === 1">
                  <p>Es-tu sûr.e d’avoir été au bout de ce support ?</p>
                  <div class="flex gap-2 justify-center items-center">
                    <p-button
                      label="Oui j'ai terminé"
                      outlined
                      severity="light"
                      @click="completeSupport"
                      rounded
                    />
                    <p-button
                      label="Je continue mon support"
                      @click="toggleConfirmCloseOverlay"
                      severity="light"
                      rounded
                    />
                  </div>
                  <a class="absolute bottom-4 underline" @click="closingStep = 2"
                    >Quitter le support</a
                  >
                </template>

                <!-- Template for unfinished support step 2 -->
                <template v-else-if="closingStep === 2">
                  <img :src="CloseOverlayImage" alt="Mascotte avec panneau attention" />

                  <p>
                    Attention, si tu quittes ton support tu devras le
                    <span class="font-bold">recommencer</span> !
                  </p>
                  <div class="flex gap-2 justify-center items-center">
                    <p-button
                      label="Je continue mon support"
                      @click="toggleConfirmCloseOverlay"
                      severity="light"
                      rounded
                    />
                    <p-button
                      label="Quitter"
                      @click="closeIframeDrawer"
                      outlined
                      severity="light"
                      rounded
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div :class="$style.closeContainer">
            <e-navigation-button icon="Close" :action="toggleConfirmCloseOverlay" />
          </div>
        </div>
      </template>
    </p-drawer>
  </div>
</template>

<style lang="scss" module>
.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Mode portrait */
@media screen and (orientation: portrait) {
  .drawerContainer {
    @apply flex flex-col justify-stretch h-full;
  }

  .rotatedContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    transform-origin: bottom left;
    transition:
      transform 0s ease,
      width 0.1s ease,
      height 0.1s ease;
  }

  .closeContainer {
    @apply basis-1/12 justify-center self-end content-center pr-2;
  }

  .rotatedContainer {
    width: var(--drawer-height);
    height: var(--drawer-width); /* Hauteur du Drawer devient la largeur de l'iframe */
    transform: rotate(90deg) translateX(-110%);
  }
}

/* Mode paysage */
@media screen and (orientation: landscape) {
  .drawerContainer {
    @apply flex flex-row justify-stretch h-full;
  }

  .rotatedContainer {
    @apply w-full h-full;
    transition:
      transform 0.1s ease,
      width 0.1s ease,
      height 0.1s ease;
  }

  .closeContainer {
    @apply basis-1/12 justify-self-center text-center pt-2;
  }
}
</style>
