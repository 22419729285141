// PROGRAM IMAGES
const missionMascotsImages = import.meta.glob('@/assets/images/presets/program/Missions/**/*.svg')

// LOGBOOK IMAGES
const mascotImages = import.meta.glob('@/assets/images/presets/logbook/missions/**/*.svg')

// Déclarer un objet vide pour les presets
export const presets: Record<string, any> = {}

// Fonction utilitaire pour charger les images dynamiques
const loadImages = async (images: Record<string, any>) => {
  const loadedImages: Record<string, string> = {}

  for (const path in images) {
    const parts = path.split('/')
    const key = parts[parts.length - 1].replace('.svg', '') // Nom de l'image sans extension
    const module = await images[path]() // Charger l'image
    loadedImages[key] = module.default // Récupérer le chemin de l'image
  }

  return loadedImages
}

// Fonction pour charger toutes les images et mettre à jour les presets
export const loadPresets = async () => {
  const logbookMissionMascots = await loadImages(mascotImages)
  const missionMascots = await loadImages(missionMascotsImages)

  presets.axe_3 = {
    name: 'preset_orange',
    description: 'This is preset1',
    value: {
      tag: 'bg-orange-300 text-orange-900',
      taskTag: 'bg-orange-100 text-orange-800',
      missionBlockGradient: 'bg-gradient-linear-03',
      courses: [
        {
          missionsImages: [
            logbookMissionMascots['MASCOTTE_Axe_3_Parcours_1_Mission_1'],
            logbookMissionMascots['MASCOTTE_Axe_3_Parcours_1_Mission_2'],
            logbookMissionMascots['MASCOTTE_Axe_3_Parcours_1_Mission_3'],
            logbookMissionMascots['MASCOTTE_Axe_3_Parcours_1_Mission_4'],
            logbookMissionMascots['MASCOTTE_Axe_3_Parcours_1_Mission_5']
          ]
        }
      ],
      missionMascots: [
        missionMascots['Parcours_1_Mission_1'],
        missionMascots['Parcours_1_Mission_2'],
        missionMascots['Parcours_1_Mission_3'],
        missionMascots['Parcours_1_Mission_4'],
        missionMascots['Parcours_1_Mission_5']
      ]
    }
  }
}
