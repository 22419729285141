import { createRouter, createWebHistory, type Router } from 'vue-router'
import { routes } from '@/router/routes'
import { registerRouteToHistory } from '@/router/utils'
import type { App } from 'vue'

const createNabooRouter = (app: App): Router => {
  const nabooRouter = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: routes(app)
  })

  nabooRouter.afterEach((to) => {
    document.title = `Naboo | ${to.meta.title || 'Accueil'}`
    registerRouteToHistory(to)
  })

  return nabooRouter
}

export default createNabooRouter
