<script setup lang="ts">
import { default as PImage } from 'primevue/image'
import { default as PButton } from 'primevue/button'
import { default as PSkeleton } from 'primevue/skeleton'
import { computed, onMounted, ref } from 'vue'
import BOrbits from '@/components/backgrounds/BOrbits.vue'
import MissionBlock from '@/views/logbook/blocks/MissionBlock.vue'
import TestBlock from '@/views/logbook/blocks/TestBlock.vue'
import { useProfileStore } from '@/stores/profile'
import { storeToRefs } from 'pinia'
import { useProgressStore } from '@/stores/progress'
import MProgress from '@/components/modules/progress/MProgress.vue'
import type { IProgressMission, IProgressQuiz } from '@/assets/ts/types/progress/Progress'
import MProgressPlaceholder from '@/components/modules/progress/MProgressPlaceholder.vue'
import MissionBlockPlaceholder from '@/views/logbook/blocks/MissionBlockPlaceholder.vue'

const { firstName, userPicture, toggleProfile } = useProfileStore()
const { globalProgress, testsToPass, missionsInProgress } = storeToRefs(useProgressStore())

const loading = ref(true)
const showProfile = ref(false)

onMounted(async () => {
  await getGlobalProgress()

  loading.value = false
})

const { getGlobalProgress } = useProgressStore()
const isMissionModuleHasDiagToDo = (mission: IProgressMission) => {
  return !!testsToPass.value.find((test: IProgressQuiz) => {
    if (test.learningCourseId === mission.learningCourseId) {
      return true
    }
  })
}

const missionsToDisplay = computed(() => {
  const missions: IProgressMission[] = []

  missionsInProgress.value.forEach((mission: IProgressMission) => {
    if (!isMissionModuleHasDiagToDo(mission)) {
      missions.push(mission)
    }
  })

  return missions
})
</script>

<template>
  <b-orbits />
  <div
    :class="$style.VLogbook"
    class="w-full h-full px-4 max-w-screen-sm mx-auto overflow-auto pb-4"
  >
    <div class="flex justify-between items-center my-4">
      <p-skeleton v-if="loading" width="10rem" height="2rem" />
      <div v-else class="text-white text-3xl font-bold font-secondary">
        Hello <span class="font-primary extra-light">{{ firstName }}</span> !
      </div>
      <p-skeleton
        v-if="loading"
        width="2.5rem"
        height="2.5rem"
        class="border-2 border-white border-solid rounded-full"
      />
      <p-button v-else text class="p-0" @click="toggleProfile">
        <p-image
          class="border-2 border-white border-solid rounded-full"
          style="width: 2.5rem; height: 2.5rem"
          imageClass="w-full h-full"
          :src="userPicture"
          alt="WelcomeProgress"
        />
      </p-button>
    </div>

    <m-progress-placeholder v-if="loading" />
    <m-progress
      v-else
      :completed="globalProgress.completed"
      :inProgress="globalProgress.inProgress"
      :percentage="globalProgress.progress"
    />

    <div class="flex flex-col justify-start gap-4 mt-3">
      <template v-if="!loading && testsToPass.length > 0">
        <test-block
          v-for="(test, i) in testsToPass"
          :key="`test-d-entree-${i}`"
          :quiz="test"
          :highlight="i === 0"
        />
      </template>

      <p-skeleton v-if="loading" width="10rem" />
      <h2 class="brand-heading medium" v-if="missionsToDisplay.length > 0 && !loading">
        Mes missions
      </h2>

      <template v-if="loading">
        <mission-block-placeholder v-for="i in 3" :key="i" />
      </template>

      <template v-if="!loading && missionsToDisplay.length > 0">
        <div class="flex flex-col gap-4">
          <mission-block
            v-for="(mission, i) in missionsToDisplay"
            :key="i"
            :mission="mission"
            :highlight="i === 0"
            @click="
              $router.push({
                name: 'mission',
                params: { courseId: mission.learningCourseId, moduleId: mission.learningModuleId }
              })
            "
          />
        </div>
      </template>
    </div>
  </div>
</template>

<style module>
.VLogbook {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: var(--linear-02);
    z-index: -1;
  }
}
</style>
