<script setup lang="ts">
import { default as PTag } from 'primevue/tag'
import { default as PImage } from 'primevue/image'
import { computed } from 'vue'
import EProgressBar from '@/components/elements/progressBar/EProgressBar.vue'
import type { IProgressMission } from '@/assets/ts/types/progress/Progress'
import { presets } from '@/assets/ts/presets'
import { useProfileStore } from '@/stores/profile'
import { storeToRefs } from 'pinia'
import vHighLightAndDuplicate from '@/assets/ts/directives/DuplicateAndHighlight'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps<{
  mission: IProgressMission
  highlight?: boolean
}>()

const { preferences } = storeToRefs(useProfileStore())

const taskCount = computed(() => {
  return `${props.mission.countCompletedLearningGrain}/${props.mission.countTotalLearningGrain}`
})

const explorationName = computed(() => {
  const explorationName = props.mission.learningCourseName
  if (props.mission.learningModuleOrder < 10) {
    return `${explorationName}-0${props.mission.learningModuleOrder}`
  } else if (props.mission.learningModuleOrder < 100) {
    return `${explorationName}-${props.mission.learningModuleOrder}`
  } else {
    return `${explorationName}-00`
  }
})

const { putPreferences } = useProfileStore()

const completeMissionOnboarding = async () => {
  console.log('completeMissionOnboarding')
  const newPreferences = {
    ...preferences.value
  }

  newPreferences.onboarding.isModuleCompleted = true

  await putPreferences(newPreferences)

  await router.push({
    name: 'mission',
    params: { courseId: props.mission.learningCourseId, moduleId: props.mission.learningModuleId }
  })
}
</script>

<template>
  <div
    class="bg-white p-4 rounded-lg relative flex items-center relative bg-white shadow-2xl"
    v-high-light-and-duplicate="{
      enabled: props.highlight && !preferences.onboarding.isModuleCompleted,
      message: 'Tu as débloqué<br/> <span class=\'font-bold\'>ta première mission</span>',
      action: completeMissionOnboarding
    }"
  >
    <div class="grow relative">
      <p-tag
        class="uppercase font-secondary font-medium px-2 py-0"
        :class="[
          mission.learningCourseId === 2
            ? 'bg-purple-300 text-purple-900'
            : 'bg-orange-300 text-orange-900 '
        ]"
        rounded
      >
        MISSION {{ explorationName }}
      </p-tag>
      <p class="text-primary font-bold mt-1 mb-8">{{ mission.title }}</p>

      <e-progress-bar
        :max="mission.countTotalLearningGrain"
        :value="mission.countCompletedLearningGrain"
        :rounded="true"
      >
        <template #content>
          <p class="font-small text-primary mt-1">{{ taskCount }} tâches</p>
        </template>
      </e-progress-bar>
    </div>

    <p-image
      class="relative ml-auto"
      :src="
        presets.axe_3.value.courses[mission.learningCourseId - 1].missionsImages[
          props.mission.learningModuleOrder - 1
        ]
      "
    />
  </div>
</template>
