import { v4 as uuidv4 } from 'uuid'
import type {
  IActivityObject,
  IActor,
  IAssessmentActivityObject,
  IContext,
  ILaunchedApplication,
  IResult,
  IVerb,
  IXAPIStatement,
  UUID
} from '@/assets/ts/types/xAPI/xAPI'
import type {
  ICompletedLearningCourse,
  ICompletedLearningGrain,
  ICompletedLearningModule,
  ICompletedLearningSupport,
  IInitializedLearningCourse,
  IInitializedLearningGrain,
  IInitializedLearningModule,
  IInitializedLearningSupport
} from '@/assets/ts/types/xAPI/learning'
import type {
  IAnseredQuestion,
  IAnsweredMatchingQuestion,
  IAnsweredMultipleChoiceQuestion,
  IAnsweredSequencingQuestion,
  IAnsweredSingleChoiceQuestion,
  IAnsweredTrueFalseQuestion,
  IAttemptedQuiz,
  IChoiceActivityObject,
  ICompletedQuiz,
  IMatchingActivityObject,
  ISequencingActivityObject,
  ITrueFalseActivityObject
} from '@/assets/ts/types/xAPI/quiz'
import { LearningEnums } from '@/assets/ts/types/learning/enums'

// Application
type LaunchedVerb = IVerb & {
  id: 'http://adlnet.gov/expapi/verbs/launched'
  display: { 'en-US': 'Launched' }
}
// Grain & Module & Support
type InitializedVerb = IVerb & {
  id: 'http://adlnet.gov/expapi/verbs/initialized'
  display: { 'en-US': 'Initialized' }
}
type CompletedVerb = IVerb & {
  id: 'http://adlnet.gov/expapi/verbs/completed'
  display: { 'en-US': 'Completed' }
}
// Quiz & Question
type AttemptedVerb = IVerb & {
  id: 'http://adlnet.gov/expapi/verbs/attempted'
  display: { 'en-US': 'Attempted' }
}
type AnsweredVerb = IVerb & {
  id: 'http://adlnet.gov/expapi/verbs/answered'
  display: { 'en-US': 'Answered' }
}

export class xAPIMessageBuilder {
  // Function to create a generic base message
  private static generateBaseMessage<T extends IXAPIStatement>(
    messageData: Omit<T, 'id' | 'timestamp'>
  ): T {
    return {
      id: uuidv4(),
      timestamp: new Date().toISOString(),
      ...messageData
    } as T
  }

  private static createActor(actorUUID: UUID): IActor {
    return {
      objectType: 'Agent',
      account: {
        homePage: 'https://naboo-edu.fr',
        name: actorUUID
      }
    }
  }

  private static createContext(
    contextRegistrationUUID: UUID,
    platform: 'web' | 'pwa',
    language: string,
    extensions: Record<string, UUID> = {},
    parentActivities?: IActivityObject[]
  ): IContext & {
    platform: 'web' | 'pwa'
    language: string
    contextActivities: { parent: IActivityObject[] }
  } {
    const context: IContext = {
      registration: contextRegistrationUUID,
      platform,
      language,
      extensions
    }

    // Si `parentActivities` est fourni, ajoute `contextActivities` avec `parent`
    if (parentActivities) {
      ;(
        context as IContext & { contextActivities: { parent: IActivityObject[] } }
      ).contextActivities = {
        parent: parentActivities
      }
    }

    return context as IContext & {
      platform: 'web' | 'pwa'
      language: string
      contextActivities: { parent: IActivityObject[] }
    }
  }

  private static createObject(id: string, name: Record<string, string>): IActivityObject

  private static createObject(
    id: string,
    name: Record<string, string>,
    type: 'http://adlnet.gov/expapi/activities/assessment'
  ): IAssessmentActivityObject

  private static createObject(
    id: string,
    name: Record<string, string>,
    interactionType: 'true-false',
    correctResponsesPattern: string[]
  ): ITrueFalseActivityObject

  private static createObject(
    id: string,
    name: Record<string, string>,
    interactionType: 'choice',
    correctResponsesPattern: string[],
    choices: Array<{ id: string; description: Record<string, string> }>
  ): IChoiceActivityObject

  private static createObject(
    id: string,
    name: Record<string, string>,
    interactionType: 'matching',
    correctResponsesPattern: string[],
    source: Array<{ id: string; description: Record<string, string> }>,
    target: Array<{ id: string; description: Record<string, string> }>
  ): IMatchingActivityObject

  private static createObject(
    id: string,
    name: Record<string, string>,
    interactionType: 'sequencing',
    correctResponsesPattern: string[],
    choices: Array<{ id: string; description: Record<string, string> }>
  ): ISequencingActivityObject

  private static createObject(
    id: string,
    name: Record<string, string>,
    interactionTypeOrType?:
      | 'true-false'
      | 'choice'
      | 'matching'
      | 'sequencing'
      | 'http://adlnet.gov/expapi/activities/assessment',
    correctResponsesPattern: string[] = [],
    choicesOrSource?: Array<{ id: string; description: Record<string, string> }>,
    target?: Array<{ id: string; description: Record<string, string> }>
  ): IActivityObject {
    const baseObject: IActivityObject = {
      objectType: 'Activity',
      id,
      definition: {
        name,
        interactionType: undefined,
        correctResponsesPattern: correctResponsesPattern.length
          ? [correctResponsesPattern.join('[,]')]
          : undefined
      }
    }

    // Handle assessment type
    if (interactionTypeOrType === 'http://adlnet.gov/expapi/activities/assessment') {
      return {
        ...baseObject,
        definition: {
          ...baseObject.definition,
          type: 'http://adlnet.gov/expapi/activities/assessment'
        }
      } as IAssessmentActivityObject
    }

    // Handle true-false type
    if (interactionTypeOrType === 'true-false') {
      return {
        ...baseObject,
        definition: {
          ...baseObject.definition,
          interactionType: 'true-false',
          correctResponsesPattern
        }
      } as ITrueFalseActivityObject
    }

    // Handle choice type
    if (interactionTypeOrType === 'choice') {
      return {
        ...baseObject,
        definition: {
          ...baseObject.definition,
          interactionType: 'choice',
          correctResponsesPattern,
          choices: choicesOrSource
        }
      } as IChoiceActivityObject
    }

    // Handle matching type
    if (interactionTypeOrType === 'matching') {
      return {
        ...baseObject,
        definition: {
          ...baseObject.definition,
          interactionType: 'matching',
          correctResponsesPattern,
          source: choicesOrSource,
          target
        }
      } as IMatchingActivityObject
    }

    // Handle sequencing type
    if (interactionTypeOrType === 'sequencing') {
      return {
        ...baseObject,
        definition: {
          ...baseObject.definition,
          interactionType: 'sequencing',
          correctResponsesPattern,
          choices: choicesOrSource
        }
      } as ISequencingActivityObject
    }

    // Default return if interactionType does not match any specific type
    return baseObject
  }

  // Application launch message
  static createLaunchedApplicationMessage(
    actorUUID: UUID,
    contextRegistrationUUID: UUID
  ): ILaunchedApplication {
    const verb: LaunchedVerb = {
      id: 'http://adlnet.gov/expapi/verbs/launched',
      display: { 'en-US': 'Launched' }
    }

    return this.generateBaseMessage<ILaunchedApplication>({
      actor: this.createActor(actorUUID),
      verb,
      object: this.createObject('https://naboo-edu.fr/activities/application', {
        'en-US': 'Application'
      }),
      context: this.createContext(contextRegistrationUUID, 'web', 'en-US')
    })
  }

  // Learning Course Messages
  static createInitializedLearningCourseMessage(input: {
    actorUUID: UUID
    contextRegistrationUUID: UUID
    courseId: string
    platform: 'web' | 'pwa'
  }): IInitializedLearningCourse {
    const verb: InitializedVerb = {
      id: 'http://adlnet.gov/expapi/verbs/initialized',
      display: { 'en-US': 'Initialized' }
    }
    return this.generateBaseMessage<IInitializedLearningCourse>({
      actor: this.createActor(input.actorUUID),
      verb,
      object: this.createObject(`https://naboo-edu.fr/activities/courses/${input.courseId}`, {
        'en-US': `Learning Course ${input.courseId}`
      }),
      context: this.createContext(input.contextRegistrationUUID, input.platform, 'fr-FR')
    })
  }

  static createCompletedLearningCourseMessage(
    actor: IActor,
    contextRegistrationUUID: UUID,
    courseId: string,
    platform: 'web' | 'pwa',
    language: string
  ): ICompletedLearningCourse {
    const verb: CompletedVerb = {
      id: 'http://adlnet.gov/expapi/verbs/completed',
      display: { 'en-US': 'Completed' }
    }
    return this.generateBaseMessage<ICompletedLearningCourse>({
      actor,
      verb,
      object: this.createObject(`https://naboo-edu.fr/activities/courses/${courseId}`, {
        'en-US': `Learning Course ${courseId}`
      }),
      context: this.createContext(contextRegistrationUUID, platform, language)
    })
  }

  // Learning Module Messages
  static createInitializedLearningModuleMessage(input: {
    actorUUID: UUID
    contextRegistrationUUID: UUID
    moduleId: string
    courseId: string
    platform: 'web' | 'pwa'
  }): IInitializedLearningModule {
    const verb: InitializedVerb = {
      id: 'http://adlnet.gov/expapi/verbs/initialized',
      display: { 'en-US': 'Initialized' }
    }
    return this.generateBaseMessage<IInitializedLearningModule>({
      actor: this.createActor(input.actorUUID),
      verb,
      object: this.createObject(
        `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}`,
        { 'en-US': `Learning Module ${input.moduleId}` }
      ),
      context: this.createContext(
        input.contextRegistrationUUID,
        input.platform,
        'fr-FR',
        undefined,
        [
          {
            objectType: 'Activity',
            id: `https://naboo-edu.fr/activities/courses/${input.courseId}`,
            definition: { name: { 'en-US': `Learning Course ${input.courseId}` } }
          }
        ]
      )
    })
  }

  static createCompletedLearningModuleMessage(input: {
    actorUUID: UUID
    contextRegistrationUUID: UUID
    courseId: string
    moduleId: string
    platform: 'web' | 'pwa'
  }): ICompletedLearningModule {
    const verb: CompletedVerb = {
      id: 'http://adlnet.gov/expapi/verbs/completed',
      display: { 'en-US': 'Completed' }
    }
    return this.generateBaseMessage<ICompletedLearningModule>({
      actor: this.createActor(input.actorUUID),
      verb,
      object: this.createObject(
        `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}`,
        { 'en-US': `Learning Module ${input.moduleId}` }
      ),
      context: this.createContext(
        input.contextRegistrationUUID,
        input.platform,
        'fr-FR',
        undefined,
        [
          {
            objectType: 'Activity',
            id: `https://naboo-edu.fr/activities/courses/${input.courseId}`,
            definition: { name: { 'en-US': `Learning Course ${input.courseId}` } }
          }
        ]
      )
    })
  }

  // LEARNING GRAIN MESSAGES
  static createInitializedLearningGrainMessage(input: {
    actorUUID: UUID
    contextRegistrationUUID: UUID
    moduleId: string
    grainId: string
    courseId: string
    platform: 'web' | 'pwa'
  }): IInitializedLearningGrain {
    const verb: InitializedVerb = {
      id: 'http://adlnet.gov/expapi/verbs/initialized',
      display: { 'en-US': 'Initialized' }
    }

    return this.generateBaseMessage<IInitializedLearningGrain>({
      actor: this.createActor(input.actorUUID),
      verb,
      object: this.createObject(
        `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}/grains/${input.grainId}`,
        {
          'en-US': `Learning Grain ${input.grainId}`
        }
      ),
      context: this.createContext(
        input.contextRegistrationUUID,
        input.platform,
        'fr-FR',
        undefined,
        [
          {
            objectType: 'Activity',
            id: `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}`,
            definition: { name: { 'en-US': `Learning Module ${input.moduleId}` } }
          }
        ]
      )
    })
  }

  static createCompletedLearningGrainMessage(input: {
    actorUUID: UUID
    contextRegistrationUUID: UUID
    courseId: string
    moduleId: string
    grainId: string
    platform: 'web' | 'pwa'
  }): ICompletedLearningGrain {
    const verb: CompletedVerb = {
      id: 'http://adlnet.gov/expapi/verbs/completed',
      display: { 'en-US': 'Completed' }
    }
    return this.generateBaseMessage<ICompletedLearningGrain>({
      actor: this.createActor(input.actorUUID),
      verb,
      object: this.createObject(
        `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}/grains/${input.grainId}`,
        {
          'en-US': `Learning Grain ${input.grainId}`
        }
      ),
      context: this.createContext(
        input.contextRegistrationUUID,
        input.platform,
        'fr-FR',
        undefined,
        [
          {
            objectType: 'Activity',
            id: `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}`,
            definition: { name: { 'en-US': `Learning Module ${input.moduleId}` } }
          }
        ]
      )
    })
  }

  // LEARNING SUPPORT MESSAGES
  static createInitializedLearningSupportMessage(input: {
    actorUUID: UUID
    contextRegistrationUUID: UUID
    supportId: string
    grainId: string
    moduleId: string
    courseId: string
    platform: 'web' | 'pwa'
  }): IInitializedLearningSupport {
    const verb: InitializedVerb = {
      id: 'http://adlnet.gov/expapi/verbs/initialized',
      display: { 'en-US': 'Initialized' }
    }

    return this.generateBaseMessage<IInitializedLearningSupport>({
      actor: this.createActor(input.actorUUID),
      verb,
      object: this.createObject(
        `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}/grains/${input.grainId}/supports/${input.supportId}`,
        {
          'en-US': `Learning Support ${input.supportId}`
        }
      ),
      context: this.createContext(
        input.contextRegistrationUUID,
        input.platform,
        'fr-FR',
        undefined,
        [
          {
            objectType: 'Activity',
            id: `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}/grains/${input.grainId}`,
            definition: { name: { 'en-US': `Learning Grain ${input.grainId}` } }
          }
        ]
      )
    })
  }

  static createCompletedLearningSupportMessage(input: {
    actorUUID: UUID
    contextRegistrationUUID: UUID
    supportId: string
    grainId: string
    moduleId: string
    courseId: string
    platform: 'web' | 'pwa'
    duration: string
  }): ICompletedLearningSupport {
    const verb: CompletedVerb = {
      id: 'http://adlnet.gov/expapi/verbs/completed',
      display: { 'en-US': 'Completed' }
    }
    return this.generateBaseMessage<ICompletedLearningSupport>({
      actor: this.createActor(input.actorUUID),
      verb,
      object: this.createObject(
        `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}/grains/${input.grainId}/supports/${input.supportId}`,
        {
          'en-US': `Learning Support ${input.supportId}`
        }
      ),
      context: this.createContext(
        input.contextRegistrationUUID,
        input.platform,
        'fr-FR',
        undefined,
        [
          {
            objectType: 'Activity',
            id: `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}/grains/${input.grainId}`,
            definition: { name: { 'en-US': `Learning Grain ${input.grainId}` } }
          }
        ]
      ),
      result: {
        success: true,
        completion: true,
        duration: input.duration
      }
    })
  }

  static createAttemptedQuizMessage(input: {
    quizType: LearningEnums.QuizType
    actorUUID: UUID
    contextRegistrationUUID: UUID
    courseId: string
    moduleId?: string
    grainId?: string
    quizId: string
    attemptSessionUUID: UUID
    platform: 'web' | 'pwa'
    language: string
  }): IAttemptedQuiz {
    const messageUrl =
      input.quizType === LearningEnums.QuizType.DIAG
        ? `https://naboo-edu.fr/activities/courses/${input.courseId}`
        : `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}/grains/${input.grainId}`

    const verb: AttemptedVerb = {
      id: 'http://adlnet.gov/expapi/verbs/attempted',
      display: { 'en-US': 'Attempted' }
    }

    return this.generateBaseMessage<IAttemptedQuiz>({
      actor: this.createActor(input.actorUUID),
      verb,
      object: this.createObject(
        `${messageUrl}/quizzes/${input.quizId}`,
        {
          'fr-FR': `Quiz ${input.quizId}`
        },
        'http://adlnet.gov/expapi/activities/assessment'
      ),
      context: this.createContext(
        input.contextRegistrationUUID,
        input.platform,
        input.language,
        { 'https://naboo-edu.fr/extensions/attempt-session-uuid': input.attemptSessionUUID },
        [
          {
            objectType: 'Activity',
            id: `${messageUrl}/quizzes/${input.quizId}`,
            definition: { name: { 'en-US': `Quiz ${input.quizId}` } }
          }
        ]
      )
    })
  }

  static createAnsweredQuestionMessage(input: {
    actorUUID: UUID
    contextRegistrationUUID: UUID
    courseId: string
    duration: string
    moduleId: string | null
    grainId?: string
    quizId: string
    quizType: LearningEnums.QuizType
    questionId: string
    questionLabel: string
    response: string[]
    success: boolean
    score: number
    target?: any[]
    source?: any[]
    maxScore: number
    minScore: number
    attemptSessionUUID: UUID
    platform: 'web' | 'pwa'
    language: string
    interactionType: 'true-false' | 'choice' | 'matching' | 'sequencing'
    correctResponsesPattern: string[]
    choices?: Array<{ id: string; description: { [key: string]: string } }>
  }): IAnseredQuestion {
    const messageUrl =
      input.quizType === LearningEnums.QuizType.DIAG
        ? `https://naboo-edu.fr/activities/courses/${input.courseId}`
        : `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}/grains/${input.grainId}`

    const actor = this.createActor(input.actorUUID)
    const verb: AnsweredVerb = {
      id: 'http://adlnet.gov/expapi/verbs/answered',
      display: { 'en-US': 'Answered' }
    }

    const minScore = input.minScore
    const maxScore = input.maxScore

    const result: IResult = {
      success: input.success,
      response: input.response.join('[,]'),
      score: {
        raw: input.score,
        min: minScore,
        max: input.maxScore,
        scaled: (input.score - minScore) / (maxScore - minScore)
      },
      duration: input.duration
    }

    const context = this.createContext(
      input.contextRegistrationUUID,
      input.platform,
      input.language,
      { 'https://naboo-edu.fr/extensions/attempt-session-uuid': input.attemptSessionUUID },
      [
        {
          objectType: 'Activity',
          id: `${messageUrl}quizzes/${input.quizId}`,
          definition: { name: { 'en-US': `Quiz ${input.quizId}` } }
        }
      ]
    )

    if (input.target && input.source) {
      return this.generateBaseMessage<IAnsweredMatchingQuestion>({
        actor,
        verb,
        object: this.createObject(
          `${messageUrl}/quizzes/${input.quizId}/questions/${input.questionId}`,
          { 'en-US': input.questionLabel },
          'matching',
          input.correctResponsesPattern,
          input.source,
          input.target
        ),
        context,
        result: {
          success: input.success,
          response: input.response.join('[,]'),
          score: {
            raw: input.score,
            min: minScore,
            max: input.maxScore,
            scaled: (input.score - minScore) / (maxScore - minScore)
          },
          duration: input.duration
        }
      })
    }

    if (input.interactionType === 'true-false') {
      return this.generateBaseMessage<IAnsweredTrueFalseQuestion>({
        actor,
        verb,
        object: this.createObject(
          `${messageUrl}/quizzes/${input.quizId}/questions/${input.questionId}`,
          { 'en-US': input.questionLabel },
          'true-false',
          input.correctResponsesPattern
        ),
        context,
        result
      })
    }

    if (input.choices && input.interactionType === 'sequencing') {
      return this.generateBaseMessage<
        | IAnsweredSingleChoiceQuestion
        | IAnsweredMultipleChoiceQuestion
        | IAnsweredSequencingQuestion
      >({
        actor,
        verb,
        object: this.createObject(
          `${messageUrl}/quizzes/${input.quizId}/questions/${input.questionId}`,
          { 'en-US': input.questionLabel },
          input.interactionType,
          input.correctResponsesPattern,
          input.choices
        ),
        context,
        result
      })
    }

    if (input.choices && input.interactionType === 'choice') {
      return this.generateBaseMessage<
        | IAnsweredSingleChoiceQuestion
        | IAnsweredMultipleChoiceQuestion
        | IAnsweredSequencingQuestion
      >({
        actor,
        verb,
        object: this.createObject(
          `${messageUrl}/quizzes/${input.quizId}/questions/${input.questionId}`,
          { 'en-US': input.questionLabel },
          input.interactionType,
          input.correctResponsesPattern,
          input.choices
        ),
        context,
        result
      })
    }

    throw new Error('Invalid interaction type')
  }

  // COMPLETE quizz method
  static createCompletedQuizMessage(input: {
    actorUUID: UUID
    contextRegistrationUUID: UUID
    courseId: string
    moduleId: string | null
    grainId?: string
    quizId: string
    quizType: LearningEnums.QuizType
    attemptSessionUUID: UUID
    platform: 'web' | 'pwa'
    language: string
    score: number
    maxScore: number
    minScore: number | null
    duration: string
  }): ICompletedQuiz {
    const messageUrl =
      input.quizType === LearningEnums.QuizType.DIAG
        ? `https://naboo-edu.fr/activities/courses/${input.courseId}`
        : `https://naboo-edu.fr/activities/courses/${input.courseId}/modules/${input.moduleId}/grains/${input.grainId}`

    const verb: CompletedVerb = {
      id: 'http://adlnet.gov/expapi/verbs/completed',
      display: { 'en-US': 'Completed' }
    }

    const minScore = input.minScore ?? 0
    const maxScore = input.maxScore

    return this.generateBaseMessage<ICompletedQuiz>({
      actor: this.createActor(input.actorUUID),
      verb,
      object: this.createObject(
        `${messageUrl}/quizzes/${input.quizId}`,
        {
          'fr-FR': `Quiz ${input.quizId}`
        },
        'http://adlnet.gov/expapi/activities/assessment'
      ),
      context: this.createContext(
        input.contextRegistrationUUID,
        input.platform,
        input.language,
        { 'https://naboo-edu.fr/extensions/attempt-session-uuid': input.attemptSessionUUID },
        [
          {
            objectType: 'Activity',
            id: `${messageUrl}/quizzes/${input.quizId}`,
            definition: { name: { 'en-US': `Quiz ${input.quizId}` } }
          }
        ]
      ),
      result: {
        success: input.score === maxScore,
        score: {
          raw: input.score,
          min: minScore,
          max: input.maxScore,
          scaled: (input.score - minScore) / (maxScore - minScore)
        },
        completion: true,
        duration: input.duration
      }
    })
  }

  // More methods for other scenarios can be added here...
}
