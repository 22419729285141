import { defineStore, storeToRefs } from 'pinia'
import { useProfileStore } from '@/stores/profile'
import { computed } from 'vue'
import * as Sentry from '@sentry/vue'
import { useEMethodosStore } from '@/stores/emethodos'
import { NabooError } from '@/assets/ts/classes/Error'
import { useAuth0 } from '@auth0/auth0-vue'

export const useAuthStore = defineStore('Auth', () => {
  // UTILITY FUNCTIONS
  const {
    checkSession,
    loginWithRedirect,
    logout,
    idTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    user: userAuth0
  } = useAuth0()
  const { getMe, initSessionUUID, $reset: resetProfileStore } = useProfileStore()
  const { getEmethodoIsEnabled } = useEMethodosStore()

  const { me } = storeToRefs(useProfileStore())

  // ACTIONS
  function $reset() {
    // Do nothing
  }

  function buildLogoutOidcUrl() {
    let baseUrl = `https://${__AUTH0_DOMAIN__}/oidc/logout`
    baseUrl += `?id_token_hint=${idTokenClaims.value?.__raw}`
    baseUrl += `&post_logout_redirect_uri=${window.location.origin}`
    return baseUrl
  }

  /**
   * Execute the login process after the response of Auth0
   */
  async function initLogin() {
    try {
      await Promise.all([getMe(), getEmethodoIsEnabled()])
      await initSessionUUID()
      Sentry.setUser({ id: me.value.identifier } as Sentry.User)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function initLogout() {
    try {
      if (!isAuthenticated.value) return Promise.resolve()
      // Reset profile store
      resetProfileStore()
      
      // Call logout endpoint for Auth0
      await logout({
        openUrl() {
          window.location.replace(buildLogoutOidcUrl())
        }
      })
    } catch (error) {
      const nabooError = new NabooError({
        message: 'Une erreur est survenue lors de la déconnexion.',
        status: 0,
        name: 'Erreur',
        code: 'ERR_UNKNOWN',
        error: error as Error
      })
      Sentry.captureException({ error, nabooError })
      return Promise.reject(error)
    } finally {
      localStorage.clear()
      Sentry.setUser(null)
    }
    return Promise.resolve()
  }

  const getRoles = computed(
    () => (userAuth0.value?.[`${__AUTH0_DOMAIN__}/roles`] as string[]) ?? ([] as string[])
  )

  // GETTERS (COMPUTED)
  const isAdmin = computed(() => {
    return isAuthenticated.value ? getRoles.value.includes('ADMIN') : false
  })

  const isStudent = computed(() => {
    return isAuthenticated.value ? getRoles.value.includes('STUDENT') : false
  })

  return {
    $reset,
    initLogin,
    initLogout,
    checkSession,
    loginWithRedirect,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    userAuth0,
    isStudent,
    isAdmin
  }
})
