<script setup lang="ts">
// VUE, VUE ROUTER, ETC...
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
// PRIMEVUE COMPONENTS
// CUSTOM COMPONENTS
import MIframeViewerDrawer from '@/components/modules/drawer/MIframeViewerDrawer.vue'
import EIcons from '@/components/elements/icons/EIcons.vue'
import TaskSupport from '@/views/training/blocks/TaskSupport.vue'
import TaskHeader from '@/views/training/blocks/TaskHeader.vue'
import TaskPostQuiz from '@/views/training/blocks/TaskPostQuiz.vue'
import TaskPreQuiz from '@/views/training/blocks/TaskPreQuiz.vue'
// IMAGES
import { useProgramStore } from '@/stores/program'
// TYPES
import type { Support } from '@/assets/ts/classes/progress/support'

// REFS AND VARIABLES
const { mission, taskContent } = storeToRefs(useProgramStore())
const loading = ref(true)
const currentSupport = ref({} as Support)
const iframeDrawerVisible = ref(false)

const route = useRoute()

onMounted(async () => {
  await getTask(
    Number(route.params.courseId),
    Number(route.params.moduleId),
    Number(route.params.grainId)
  )

  // Set data
  loading.value = false
})

// Computed

// METHODS
const { getTask, startSupport, completeSupport } = useProgramStore()

const start = (support: Support) => {
  if (!currentSupport.value.isCompleted)
    startSupport(Number(route.params.courseId), mission.value, taskContent.value, support)

  currentSupport.value = support
  iframeDrawerVisible.value = true
}

const sendCompleteSupport = async (elapsedTime: number) => {
  if (!currentSupport.value.isCompleted)
    await completeSupport(
      Number(route.params.courseId),
      mission.value,
      taskContent.value,
      currentSupport.value,
      elapsedTime
    )

  iframeDrawerVisible.value = false
  currentSupport.value = {} as Support
}

const canSupportsBePlayed = computed(() => {
  return taskContent.value.preQuizCompleted
})

const canPostQuizBePlayed = computed(() => {
  return (
    taskContent.value.supports.filter((support) => support.isCompleted).length ===
    taskContent.value.supports.length
  )
})
</script>

<template>
  <!-- ICONS IMPORTS -->
  <e-icons :load="['Play']" />

  <!-- OVERLAY -->
  <m-iframe-viewer-drawer
    :iframeURL="currentSupport.supportUrl || ''"
    :support-is-completed="currentSupport.isCompleted ?? false"
    v-model:visible="iframeDrawerVisible"
    @complete-support="(elapsedTimeInSec) => sendCompleteSupport(elapsedTimeInSec)"
  />

  <div class="flex flex-col h-full bg-primary-night-blue">
    <!-- HEADER -->
    <task-header :title="taskContent.title" />

    <!-- CONTENT -->
    <div class="flex-1 overflow-auto w-full px-4 py-4">
      <div class="flex flex-col gap-2 max-w-96 mx-auto">
        <task-pre-quiz
          :quiz-completed="taskContent.preQuizCompleted"
          :quiz-id="taskContent.preQuizId"
        />

        <task-support
          v-for="(support, index) in taskContent.supports"
          :key="`support_${index}`"
          :index="index"
          :support="support"
          :disabled="
            !canSupportsBePlayed ||
            (taskContent.nextSupportIndex < index && taskContent.nextSupportIndex !== -1)
          "
          :is-current="taskContent.nextSupportIndex === index"
          @start-support="start(support)"
        />

        <task-post-quiz
          :quiz-score="taskContent.taskScore"
          :quiz-id="taskContent.postQuizId"
          :disabled="!canPostQuizBePlayed"
        />
      </div>
    </div>
  </div>
</template>
