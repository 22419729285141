// DuplicateAndHighlight.js
import type { DirectiveBinding } from 'vue'
import { createApp } from 'vue'
import MascotOnboarding from '@/components/onboarding/MascotOnboarding.vue'

interface DuplicateAndHighlightOptions {
  enabled: boolean
  message?: string
  action?: () => void
}

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding<DuplicateAndHighlightOptions>) {
    if (!binding.value?.enabled) return
    // Check if a cloned element already exists
    if (document.body.querySelector('.duplicate-clone')) {
      console.log("A duplicated element already exists, the directive won't execute.")
      return
    }

    const { message = 'Default Text' } = binding.value || {}
    const { action = () => {} } = binding.value || {}
    let positionName = 'bottom'

    // Clone the original element
    const clone = el.cloneNode(true) as HTMLElement
    clone.classList.add('duplicate-clone')

    // Style the clone (positioning and bringing it forward)
    clone.style.position = 'absolute'
    clone.style.zIndex = '1001' // Put the clone above the overlay

    // Get the dimensions and position of the original element
    const rect = el.getBoundingClientRect()
    clone.style.top = `${rect.top + window.scrollY}px`
    clone.style.left = `${rect.left + window.scrollX}px`
    clone.style.width = `${rect.width}px`
    clone.style.height = `${rect.height}px`

    // Create an overlay to block the rest of the page
    const overlay = document.createElement('div')
    overlay.style.position = 'fixed'
    overlay.style.top = '0'
    overlay.style.left = '0'
    overlay.style.padding = '0 1rem'
    overlay.style.width = '100vw'
    overlay.style.height = '100vh'
    overlay.style.backgroundColor = 'rgba(0, 15, 106, 0.75)' // Color and transparency
    overlay.style.zIndex = '1000' // Overlay is just below the clone

    // Append the overlay and clone to the body
    document.body.appendChild(overlay)
    document.body.appendChild(clone)

    // Dynamically create a div that will host the Vue component
    const container = document.createElement('div')
    container.style.height = '100%'
    container.style.width = '100%'
    container.style.position = 'relative'
    overlay.appendChild(container)

    // **Calculate the available space for the popup**
    const spaceBelow = window.innerHeight - rect.bottom
    const spaceAbove = rect.top

    // Default position of the popup below the element
    let popupPositionTop = rect.top + rect.height + 20

    // If there is not enough space below, position the popup above
    if (spaceBelow < 160 && spaceAbove >= 160) {
      popupPositionTop = rect.top - rect.height - 40 // Adjust height as needed
      positionName = 'top'
    }

    // Dynamically create a Vue application for the popup
    const app = createApp(MascotOnboarding, {
      message,
      onPrimaryClick: () => {
        action()
        removePopup()
      },
      // Position the popup according to the available space
      position: {
        name: positionName,
        value: {
          top: popupPositionTop,
          left: rect.left
        }
      }
    })

    // Mount the application on the container element
    const instance = app.mount(container)

    // Function to remove the popup
    const removePopup = () => {
      console.log('Removing popup')
      app.unmount() // Unmount the Vue application

      document.body.removeChild(overlay)
      document.body.removeChild(clone)
    }
  }
}
