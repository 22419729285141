<script setup lang="ts">
import { default as PImage } from 'primevue/image'
import { default as PTag } from 'primevue/tag'
import type { Mission } from '@/assets/ts/classes/progress/mission'
import type { Exploration } from '@/assets/ts/classes/progress/exploration'
import EProgressBar from '@/components/elements/progressBar/EProgressBar.vue'
import MStarGrades from '@/components/modules/grades/MStarGrades.vue'
import { computed } from 'vue'
import { presets } from '@/assets/ts/presets'

const props = defineProps<{
  mission: Mission
  exploration: Exploration
}>()

const missionLabel = computed(() => {
  let missionName = `Mission ${props.exploration.name}`

  if (props.mission.order < 10) {
    missionName += `-0${props.mission.order}`
  } else {
    missionName += `-${props.mission.order}`
  }

  return missionName
})

const presetsComp = computed(() => presets)
</script>

<template>
  <div class="flex flex-col rounded-2xl w-56 overflow-hidden">
    <div
      class="flex flex-col items-center"
      style="background: var(--linear-03)"
      :class="mission.isCompleted ? 'pt-4' : 'pt-8'"
    >
      <img
        v-if="!mission.isCompleted"
        :src="presets.axe_3.value.missionMascots[mission.order - 1]"
        class="w-20 block mb-O"
      />
      <div v-else class="flex flex-col gap-4 items-center">
        <p-tag class="bg-white text-purple-500" rounded>
          <i class="pi pi-check" /> Mission terminée
        </p-tag>
        <div class="flex items-center gap-8 w-full">
          <img
            :src="presets.axe_3.value.missionMascots[mission.order - 1]"
            class="w-20 block"
            style="transform: translateY(1rem)"
          />
          <m-star-grades size="small" :points="mission.score.score" border />
        </div>
      </div>
    </div>
    <div class="bg-white p-2 pt-8 flex flex-col items-center gap-2">
      <p-tag
        class="bg-orange-300 text-orange-900 uppercase text medium px-2 py-0"
        rounded
        :value="missionLabel"
      />
      <p class="text font-bold text-primary">{{ mission.title }}</p>
      <e-progress-bar :max="mission.countTasks" :value="mission.countCompletedTasks">
        <template #content>
          <p class="text small self-start text-primary">
            {{ mission.countCompletedTasks }}/{{ mission.countTasks }} tâches
          </p>
        </template>
      </e-progress-bar>
    </div>
  </div>
</template>
