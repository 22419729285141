<script setup lang="ts">
// PRIMEVUE COMPONENTS
import { default as PTag } from 'primevue/tag'
import { default as PButton } from 'primevue/button'
// CUSTOM COMPONENTS
import EIcon from '@/components/elements/icons/EIcon.vue'
import SupportBaseBlock from '@/views/training/blocks/SupportBaseBlock.vue'
// ROUTER
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const props = defineProps<{
  quizCompleted: boolean
  quizId: number
}>()

const startQuiz = () => {
  if (!props.quizCompleted) {
    router.push({
      name: 'quiz',
      params: {
        courseId: route.params.courseId,
        moduleId: route.params.moduleId,
        grainId: route.params.grainId,
        quizId: props.quizId
      }
    })
  }
}
</script>

<template>
  <support-base-block type="prequiz" :state="quizCompleted ? 'completed' : 'current'">
    <div class="flex items-center gap-2 w-full">
      <p-tag class="bg-orange-100 text-orange-800" rounded> Quiz </p-tag>
      <p class="font-bold">Qu'est-ce que je sais déjà ?</p>

      <p-button
        v-if="!quizCompleted"
        class="ml-auto w-12 h-12"
        severity="light"
        rounded
        @click="startQuiz"
      >
        <div class="w-6">
          <e-icon name="Play" type="svg" style="--fill: var(--primary-lavender)" />
        </div>
      </p-button>
    </div>
  </support-base-block>
</template>
